import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BackButton } from '../../../App/Components'
import { useHistory } from '../../../Router'
import { ServiceTitle, StyledContainer } from '../../style'
import { AddCustomerButton } from '../AddCustomerButton/AddCustomerButton'
import { CustomerAppointmentList } from '../CustomerAppointmentList/CustomerAppointmentList'
import { SelectPersonForAllActivities } from '../SelectPersonForAllActivities/SelectPersonForAllActivities'

import { isDepositCheck } from '../../../App/Util/isDepositCheck/isDepositCheck'
import { ISettingsState } from '../../../Settings/Store/ISettingsState'
import { useAppSelector } from '../../../store/hooks'
import { TotalPrice } from '../TotalPrice/TotalPrice'

type Props = {
  establishmentId: string
  settingsState: ISettingsState
  isSelectAPerson: boolean
}

export const OverviewHeader = ({
  settingsState,
  establishmentId,
  isSelectAPerson,
}: Props) => {
  const [totalPrice, setTotalPrice] = useState<number>(0)
  const [totalDeposit, setTotalDeposit] = useState<number>(0)
  const [isDeposit, setIsDeposit] = useState<boolean>(false)

  const { t } = useTranslation()
  const history = useHistory()

  const customers = useAppSelector((state) => state.appointment.customers)
  const settings = settingsState.values
  const isShowPrice = settings.showPrice

  useEffect(() => {
    // calculation price
    const customerActivities = customers
      .map((customer) => customer.activities)
      .flat(1)
    const priceSum = customerActivities.reduce((total, newValue) => {
      const price =
        newValue.type === 'menuActivity'
          ? newValue.activityMenuPrice
          : newValue.price
      return total + (typeof price === 'number' ? price : 0)
    }, 0)

    // calculation deposit
    const depositSum = customerActivities.reduce(
      (total, newValue) =>
        total + (typeof newValue.deposit === 'number' ? newValue.deposit : 0),
      0
    )

    // Check if deposit is enabled
    const isShowDeposit = customers.some((customer) => {
      if (customer.activities) {
        return customer.activities.some((activity) => activity.deposit)
      }
      return false
    })
    const shouldCustomerPayDeposit = isDepositCheck(settingsState, depositSum)
    setIsDeposit(isShowDeposit && shouldCustomerPayDeposit)

    setTotalPrice(priceSum)
    setTotalDeposit(depositSum)
  }, [customers])

  // TODO prevPath does not work, fix tis after migration of react router @help
  const prevPath: string = history.location.state
    ? history.location.state.prevPath
    : ''
  // TODO possible breaking logic when changing router
  const selectPersonPath = `/${establishmentId}/select-person`

  return (
    <>
      <ServiceTitle fontcolor={settings.highlightColor}>
        {t('Services')}
      </ServiceTitle>
      <BackButton
        goBack={prevPath.includes(selectPersonPath) ? -2 : -1}
        isAppointment
      />
      <StyledContainer highlightcolor={settings.highlightColor}>
        <CustomerAppointmentList />
        <AddCustomerButton
          isMultipleCustomer={settings.isMultipleCustomersEnabled}
        />
        <TotalPrice
          totalPrice={totalPrice}
          isShowPrice={isShowPrice}
          totalDeposit={totalDeposit}
          isShowDeposit={isDeposit}
        />
        <SelectPersonForAllActivities isPersonSelectable={isSelectAPerson} />
      </StyledContainer>
    </>
  )
}
