import { Reducer } from 'redux'
import { getOptInsFromClient, OptIns, submitOptInToClient } from './client'
import { AppDispatch } from '../store/store'

const OPTINS_LOADING = 'OPTINS_LOADING'
const OPTINS_SUCCESS = 'OPTINS_SUCCESS'

type OptInsSuccess = {
    type: typeof OPTINS_SUCCESS
    payload: {
      optIns: OptIns
    }
  }
const getOptInsSuccess = (optIns: OptIns): OptInsSuccess => ({
  type: OPTINS_SUCCESS,
  payload: {
    optIns,
  },
})
  
  type OptInsLoading = {
    type: typeof OPTINS_LOADING
}
const getOptInsLoading =(): OptInsLoading  =>({
  type: OPTINS_LOADING,
})

export function getOptIns(customerUuid: string, customerToken: string) {
  return (dispatch: AppDispatch) => {
    dispatch(getOptInsLoading())

    return getOptInsFromClient(customerUuid, customerToken).then(
      (response: OptIns) => {
        dispatch(getOptInsSuccess(response))
      }
    )
  }
}

export function submitOptin(
  customerUuid: string, 
  customerToken: string, 
  isTransactional: boolean, 
  isMarketing: boolean) {
  return () => submitOptInToClient(customerUuid, customerToken, isTransactional, isMarketing)
}



type OptInState = {
    optIns: OptIns | null
    loading: boolean
}

const initialState: OptInState = {
  optIns: null,
  loading: true,
}

export const reducer: Reducer<OptInState> = (
  state: OptInState = initialState,
  action: any
): OptInState => {
  switch (action.type) {
    case OPTINS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case OPTINS_SUCCESS:
      return {
        ...state,
        loading: false,
        optIns: action.payload.optIns,
      }
    default: 
      return state
  }
}