import React, { useEffect, useState } from 'react'
import { CheckBox } from 'react-native'
import { useParams } from 'react-router'

import { useTranslation } from 'react-i18next'
import { getOptIns, submitOptin } from './store'
import {
  OptionsContainer,
  StyledScrollView,
} from '../App/Components/AppContainer'

import {
  CheckboxWrapper,
  CheckboxLabel,
  OptInFormHeading,
  OptInFormContainer,
} from './style'
import { ExtendedButtonWithText } from '../CustomerForm/style'
import {
  Logo,
  Spinner,
  StatusMessageContainer,
  StatusText,
} from '../App/Components'
import { useAppDispatch, useAppSelector } from '../store/hooks'

type EndpointFeedback = {
  success: boolean
  message: string
}

export const OptInForm = () => {
  const [isTransactional, setIsTransactional] = useState<boolean>(false)
  const [isMarketing, setIsMarketing] = useState<boolean>(false)

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [endpointFeedback, setEndpointFeedback] = useState<EndpointFeedback>()

  const { customerUuid, customerToken } = useParams<{ [key: string]: string }>()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const optIns = useAppSelector((state) => state.optIns.optIns)
  const settings = useAppSelector((state) => state.settings.values)

  const onSubmit = () => {
    setIsSubmitting(true)
    dispatch(
      submitOptin(customerUuid, customerToken, isTransactional, isMarketing)
    )
      .then(() => setEndpointFeedback({ success: true, message: 'Success!' }))
      .catch(() => setEndpointFeedback({ success: false, message: 'Error!' }))
      .finally(() => setIsSubmitting(false))
  }

  useEffect(() => {
    if (optIns) {
      setIsTransactional(optIns.transactionalOptIn)
      setIsMarketing(optIns.marketingOptIn)
    }
  }, [optIns])

  useEffect(() => {
    dispatch(getOptIns(customerUuid, customerToken)).catch(() =>
      setEndpointFeedback({
        success: false,
        message:
          'Failed to load opt in details, please ask for a new email link.',
      })
    )
  }, [])

  if (endpointFeedback) {
    return (
      <StyledScrollView>
        <Logo />
        <StatusMessageContainer success={endpointFeedback.success}>
          <StatusText>{t(endpointFeedback.message)}</StatusText>
        </StatusMessageContainer>
      </StyledScrollView>
    )
  }

  if (isSubmitting || optIns === null) {
    return <Spinner />
  }

  return (
    <StyledScrollView>
      <Logo />
      <OptInFormContainer>
        <OptInFormHeading fontColor={settings.fontColor}>
          {t('Please update your email preferences.')}
        </OptInFormHeading>
        <CheckboxWrapper>
          <CheckBox
            value={isTransactional}
            onValueChange={(value) => setIsTransactional(value)}
          />
          <CheckboxLabel
            fontcolor={settings.fontColor}
            onPress={() => setIsTransactional(!isTransactional)}
          >
            {t('I want to receive transactional emails.')}
          </CheckboxLabel>
        </CheckboxWrapper>
        <CheckboxWrapper>
          <CheckBox
            value={isMarketing}
            onValueChange={(value) => setIsMarketing(value)}
          />
          <CheckboxLabel
            fontcolor={settings.fontColor}
            onPress={() => setIsMarketing(!isMarketing)}
          >
            {t('I want to receive marketing emails.')}
          </CheckboxLabel>
        </CheckboxWrapper>

        <ExtendedButtonWithText
          fontcolor={settings.highlightColor}
          backgroundcolor={settings.backgroundColor}
          onPress={onSubmit}
        >
          {t('Confirm settings')}
        </ExtendedButtonWithText>
      </OptInFormContainer>
      <OptionsContainer />
    </StyledScrollView>
  )
}
