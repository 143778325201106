import React from 'react'
import { useTranslation } from 'react-i18next'

import { Logo } from '../../App/Components'
import {
  OptionsContainer,
  StyledScrollView,
} from '../../App/Components/AppContainer'
import { Route, Switch } from '../../Router'
import { SurveyBootstrap } from '../../Survey'
import { BookingClosed } from '../style'
import { OptInForm } from '../../OptIn'
import { useAppSelector } from '../../store/hooks'

export const OnlineBookingClosed = () => {
  const settings = useAppSelector((state) => state.settings.values)

  const { fontColor } = settings

  const { t } = useTranslation()
  return (
    <Switch>
      <Route path="/:establishmentId/surveys" component={SurveyBootstrap} />
      <Route
        path="/:establishmentId/opt-in/:customerUuid/:customerToken"
        component={OptInForm}
      />
      <Route path="/">
        <StyledScrollView>
          <Logo />
          <BookingClosed textColor={fontColor}>
            {t(
              'The online booking is currently closed, please come back later.'
            )}
          </BookingClosed>
          <OptionsContainer />
        </StyledScrollView>
      </Route>
    </Switch>
  )
}
