import { URL_API } from 'react-native-dotenv'

import axios, { AxiosResponse } from 'axios'

export type OptIns = {
  customerUuid: string
  customerToken: string
  marketingOptIn: boolean
  transactionalOptIn: boolean
}

type OptInsDto = {
  customer_uuid: string
  customer_token: string
  marketing_opt_in: boolean
  transactional_opt_in: boolean
}

const mapOptInsDtoToOptIns = (optIns: OptInsDto): OptIns => ({
  customerUuid: optIns.customer_uuid,
  customerToken: optIns.customer_token,
  transactionalOptIn: optIns.transactional_opt_in,
  marketingOptIn: optIns.marketing_opt_in
})

const baseUrl = `${URL_API}/anonymous/customers/opt-in`

export const getOptInsFromClient = async (
  customerUuid: string,
  customerToken: string
): Promise<OptIns> => {
    
  const requestOptions: object = {
    method: 'GET',
    url: `${baseUrl}/${customerUuid}/${customerToken}`,
  }


  const response: AxiosResponse = await axios(requestOptions)
  return mapOptInsDtoToOptIns(response.data)

}

export const submitOptInToClient = (
  customerUuid: string,
  customerToken: string,
  isTransactional: boolean,
  isMarketing: boolean
): Promise<any>  => {
  const submitOptIn: object = {
    method: 'POST',
    url: `${baseUrl}/${customerUuid}/submit`,
    data: {
      token: customerToken,
      is_transactional_optin: isTransactional,
      is_marketing_optin: isMarketing,
    },
  }
  return axios(submitOptIn)
}

