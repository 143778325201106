import React from 'react'
import { AnnulationCondition } from '../../AnnulationCondition/AnnulationCondition'
import { BookingStatus } from '../../App/Components/BookingStatus/BookingStatus'
import { Appointment } from '../../Appointment/Appointment'
import { BookAppointment } from '../../BookAppointment/BookAppointment'
import { ExtraActivity } from '../../ExtraActivity'
import { BookLastMinute, LastMinutesOverview } from '../../LastMinutes'
import { Menu } from '../../Menu'
import { MoveAppointment } from '../../MoveAppointment/MoveAppointment'
import { PaymentFallback } from '../../PaymentFallback/PaymentFallback/PaymentFallback'
import { PreviousAppointments } from '../../PreviousAppointments/PreviousAppointments'
import { Route, Switch } from '../../Router'
import { SelectLocation } from '../../SelectLocation/SelectLocation'
import { SelectPerson } from '../../SelectPerson/SelectPerson'
import { SurveyBootstrap } from '../../Survey'
import { MainBootstrap } from '../Bootstrap/MainBootstrap'
import { BookWaitingList } from '../../BookWaitingList/BookWaitingList'
import { OptInForm } from '../../OptIn'
import { Account } from '../../Account/Account'
import { Authenticated } from '../../Authenticated/Authenticated'
import { AccountInfo } from '../../AccountInfo/AccountInfo'
import { EditPerson } from '../../MoveAppointment/components/EditPerson/EditPerson'
import AppointmentMovedSuccess from '../../MoveAppointment/components/AppointmentMovedSuccess/AppointmentMovedSuccess'
import { WorkshopOverview } from '../../Workshop/WorkshopOverview/WorkshopOverview'
import { BookWorkshop } from '../../Workshop/BookWorkshop/BookWorkshop'
import { CustomerStop } from '../../CustomerStop'
import { AccountSelection } from '../../AccountSelection'
import { AccountVerification } from '../../AccountVerification/AccountVerification'
import { PaymentStatus as InvoicePaymentStatus } from '../../Invoice/PaymentStatus/PaymentStatus'

const AutPrevAppointment = () => (
  <Authenticated
    oldTarget="planned-appointments"
    Component={PreviousAppointments}
  />
)

const AutAccount = () => (
  <Authenticated oldTarget="account" Component={Account} />
)

const AutAccountInfo = () => (
  <Authenticated oldTarget="account-info" Component={AccountInfo} />
)

export const OnlineBookingOpen = () => (
  <Switch>
    <Route path="/:establishmentId/surveys" component={SurveyBootstrap} />
    <Route
      path="/:establishmentId/opt-in/:customerUuid/:customerToken"
      component={OptInForm}
    />
    <Route exact path="/:establishmentId" component={MainBootstrap} />
    <Route exact path="/:establishmentId/last-minutes">
      <CustomerStop component={LastMinutesOverview} />
    </Route>

    <Route exact path="/:establishmentId/last-minutes/:lastMinuteId">
      <CustomerStop component={BookLastMinute} />
    </Route>

    <Route exact path="/:establishmentId/last-minutes/:lastMinuteId/book">
      <CustomerStop component={BookLastMinute} />
    </Route>

    <Route exact path="/:establishmentId/workshops">
      <CustomerStop component={WorkshopOverview} />
    </Route>

    <Route exact path="/:establishmentId/workshops/:workshopUuid/book">
      <CustomerStop component={BookWorkshop} />
    </Route>

    <Route exact path="/:establishmentId/appointment/book">
      <CustomerStop component={BookAppointment} />
    </Route>

    <Route exact path="/:establishmentId/appointment/book/status">
      <CustomerStop component={BookingStatus} />
    </Route>

    <Route exact path="/:establishmentId/waiting-list/book">
      <CustomerStop component={BookWaitingList} />
    </Route>

    <Route exact path="/:establishmentId/waiting-list/book/status">
      <CustomerStop component={BookingStatus} />
    </Route>

    <Route exact path="/:establishmentId/select-location">
      <CustomerStop component={SelectLocation} />
    </Route>

    {/* ---ACCOUNT PATH's--- */}

    <Route exact path="/:establishmentId/previous-appointments">
      <CustomerStop component={AutPrevAppointment} />
    </Route>

    <Route exact path="/:establishmentId/previous-appointments/:token">
      <CustomerStop component={AutPrevAppointment} />
    </Route>

    <Route exact path="/:establishmentId/previous-appointments/:token/:email">
      <CustomerStop component={AutPrevAppointment} />
    </Route>

    {/* ---------ACCOUNT HOME---------- */}

    <Route exact path="/:establishmentId/account">
      <CustomerStop component={AutAccount} />
    </Route>
    <Route exact path="/:establishmentId/account/:token">
      <CustomerStop component={AutAccount} />
    </Route>
    <Route exact path="/:establishmentId/account/:token/:email">
      <CustomerStop component={AutAccount} />
    </Route>

    {/* ---------ACCOUNT SETTINGS---------- */}
    <Route exact path="/:establishmentId/account-info">
      <CustomerStop component={AutAccountInfo} />
    </Route>

    <Route exact path="/:establishmentId/account-info/:token">
      <CustomerStop component={AutAccountInfo} />
    </Route>
    <Route exact path="/:establishmentId/account-info/:token/:email">
      <CustomerStop component={AutAccountInfo} />
    </Route>

    {/* ---------ACCOUNT VERIFICATION---------- */}

    <Route exact path="/:establishmentId/verification/:target/:token/:email">
      <Authenticated Component={AccountVerification} />
    </Route>

    {/* ------------------- */}

    <Route
      exact
      path="/:establishmentId/appointment/:appointmentId/payment-status/:token/:email"
    >
      <CustomerStop component={PaymentFallback} />
    </Route>

    <Route exact path="/:establishmentId/appointment">
      <CustomerStop component={Appointment} />
    </Route>

    <Route
      exact
      path="/:establishmentId/appointment/move/:previousAppointmentId"
    >
      <CustomerStop component={MoveAppointment} />
    </Route>

    <Route
      exact
      path="/:establishmentId/appointment/moved/:previousAppointmentId"
    >
      <CustomerStop component={AppointmentMovedSuccess} />
    </Route>

    <Route exact path="/:establishmentId/select-person">
      <CustomerStop component={SelectPerson} />
    </Route>

    <Route exact path="/:establishmentId/select-person/:customerIndex">
      <CustomerStop component={SelectPerson} />
    </Route>

    <Route
      exact
      path="/:establishmentId/select-person/:customerIndex/:activityId"
    >
      <CustomerStop component={SelectPerson} />
    </Route>

    <Route exact path="/:establishmentId/edit-person">
      <CustomerStop component={EditPerson} />
    </Route>

    <Route exact path="/:establishmentId/edit-person/:activityId">
      <CustomerStop component={EditPerson} />
    </Route>

    <Route exact path="/:establishmentId/activities">
      <CustomerStop component={ExtraActivity} />
    </Route>

    <Route exact path="/:establishmentId/activities/:categoryId">
      <CustomerStop component={ExtraActivity} />
    </Route>

    <Route exact path="/:establishmentId/activities/:categoryId/:activityId">
      <CustomerStop component={ExtraActivity} />
    </Route>

    <Route exact path="/:establishmentId/annulation-condition">
      <CustomerStop component={AnnulationCondition} />
    </Route>

    <Route path="/:establishmentId/menu">
      <CustomerStop component={Menu} />
    </Route>

    <Route
      exact
      path="/:establishmentId/invoice/:invoiceId/payment-status/:token/:email"
      component={InvoicePaymentStatus}
    />

    <Route path="/:establishmentId/account-selection/:userToken">
      <AccountSelection />
    </Route>
  </Switch>
)
